.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.approval {
  height: 100vh;
  background: #fff;
}
.approval .approval-title {
  width: 100%;
  height: 0.96rem;
  background: var(--main-color);
  line-height: 0.96rem;
  padding: 0 0.24rem;
  font-size: 0.32rem;
  color: #fff;
}
.approval .approval-side {
  padding: 0.2rem;
}
.approval .approval-screen {
  background: #fff;
  padding-bottom: 0.8rem;
}
.approval .approval-screen .dispaly-table {
  margin: 0.5rem 0;
}
.approval .approval-screen .approval-content {
  margin-top: 0.2rem;
  height: 1rem;
}
.approval .approval-screen .approval-content .day-limited {
  overflow: hidden;
  padding-top: 0.2rem;
}
.approval .approval-screen .approval-content .day-limited .limited-title {
  padding-top: 0.05rem;
}
.approval .approval-screen .approval-content .day-limited .limited-radio,
.approval .approval-screen .approval-content .day-limited .limited-title {
  float: left;
}
.approval .approval-screen .approval-content .day-limited .limited-radio {
  margin-left: 0.2rem;
}
.approval .approval-screen .approval-content .day-limited .limited-mount {
  border: 0.02rem solid #999;
}
.approval .approval-screen .approval-content .day-limited .limited-mount .number {
  width: 1.5rem;
  padding: 0.05rem 0.1rem;
}
.approval .approval-screen .approval-content .day-limited .approval {
  width: 1.2rem;
  height: 0.5rem;
  line-height: 0.5rem;
  text-align: center;
  background: #e6a23c;
  font-size: 0.1rem;
  border-radius: 0.1rem;
}
.approval .approval-screen .approval-btn {
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-around;
}
.approval table {
  font-size: 0.3rem;
}
.approval table th {
  width: 2.1rem;
  height: 0.6rem;
  text-align: right;
  line-height: 0.6rem;
}
.approval table .first-title {
  width: 1.4rem;
  height: 0.5rem;
  width: calc(100vw - 6rem);
  padding-right: 0.2rem;
}
.approval table .last-title {
  width: 1.8rem;
  height: 0.5rem;
  border: none;
}
.approval table tr {
  border: solid #eee;
  border-width: 0.01rem 0rem 0rem 0;
}
.approval table tr td {
  height: 0.6rem;
  text-align: right;
  line-height: 0.6rem;
}
.approval table tr .first-content {
  text-align: right;
  padding-right: 0.2rem;
  width: 2rem;
}
