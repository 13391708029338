.border-none[data-v-36f1613b]:before {
  display: none;
}
.border-none[data-v-36f1613b]:after {
  display: none;
}
.flex-fix[data-v-36f1613b] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-36f1613b]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-36f1613b] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-36f1613b] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-36f1613b] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-36f1613b] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-36f1613b] {
  *zoom: 1;
}
.clear-fix[data-v-36f1613b]:before,
.clear-fix[data-v-36f1613b]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.approval[data-v-36f1613b] {
  height: 100vh;
  background: #fff;
}
.approval .approval-title[data-v-36f1613b] {
  width: 100%;
  height: 0.96rem;
  background: var(--main-color);
  line-height: 0.96rem;
  padding: 0 0.24rem;
  font-size: 0.32rem;
  color: #fff;
}
.approval .approval-side[data-v-36f1613b] {
  padding: 0.2rem;
}
.approval .approval-screen[data-v-36f1613b] {
  background: #fff;
  padding-bottom: 0.8rem;
}
.approval .approval-screen .dispaly-table[data-v-36f1613b] {
  margin: 0.5rem 0;
}
.approval .approval-screen .approval-content[data-v-36f1613b] {
  margin-top: 0.2rem;
  height: 1rem;
}
.approval .approval-screen .approval-content .day-limited[data-v-36f1613b] {
  overflow: hidden;
  padding-top: 0.2rem;
}
.approval .approval-screen .approval-content .day-limited .limited-title[data-v-36f1613b] {
  padding-top: 0.05rem;
}
.approval .approval-screen .approval-content .day-limited .limited-radio[data-v-36f1613b],
.approval .approval-screen .approval-content .day-limited .limited-title[data-v-36f1613b] {
  float: left;
}
.approval .approval-screen .approval-content .day-limited .limited-radio[data-v-36f1613b] {
  margin-left: 0.2rem;
}
.approval .approval-screen .approval-content .day-limited .limited-mount[data-v-36f1613b] {
  border: 0.02rem solid #999;
}
.approval .approval-screen .approval-content .day-limited .limited-mount .number[data-v-36f1613b] {
  width: 1.5rem;
  padding: 0.05rem 0.1rem;
}
.approval .approval-screen .approval-content .day-limited .approval[data-v-36f1613b] {
  width: 1.2rem;
  height: 0.5rem;
  line-height: 0.5rem;
  text-align: center;
  background: #e6a23c;
  font-size: 0.1rem;
  border-radius: 0.1rem;
}
.approval .approval-screen .approval-btn[data-v-36f1613b] {
  margin-top: 0.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.approval table[data-v-36f1613b] {
  font-size: 0.3rem;
}
.approval table th[data-v-36f1613b] {
  width: 2.1rem;
  height: 0.6rem;
  text-align: right;
  line-height: 0.6rem;
}
.approval table .first-title[data-v-36f1613b] {
  width: 1.4rem;
  height: 0.5rem;
  width: calc(100vw - 6rem);
  padding-right: 0.2rem;
}
.approval table .last-title[data-v-36f1613b] {
  width: 1.8rem;
  height: 0.5rem;
  border: none;
}
.approval table tr[data-v-36f1613b] {
  border: solid #eee;
  border-width: 0.01rem 0rem 0rem 0;
}
.approval table tr td[data-v-36f1613b] {
  height: 0.6rem;
  text-align: right;
  line-height: 0.6rem;
}
.approval table tr .first-content[data-v-36f1613b] {
  text-align: right;
  padding-right: 0.2rem;
  width: 2rem;
}
